
@import "./abstracts/variables";

@import "./node_modules/bootstrap/scss/bootstrap";

body {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'Poppins', sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif; /* Or any other font you prefer */
}

ul {
  font-family: 'Lato', sans-serif; /* Or any other font you prefer */
}

:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --accent-color: #e84545;
  --accent-color-rgb: 232, 69, 69;
  --heading-color: #32353a;
  --heading-color-rgb: 50, 53, 58;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}

.custom-bg-red {
  background-color: rgba(222, 204, 204, 0.511) ;
}


/* Navbar styling */
.navbar {
  margin: 0;
  font-size: 19px;
  transition: all 0.5s ease;
}

.navbar-brand {
  width: 150px;
}

.navbar-nav .nav-link {
  color: #000;
  font-weight: 500;
  position: relative;
}

.navbar-nav .nav-link.active {
  color: #BB0404;
}

.navbar-scrolled {
  background-color: white;
  box-shadow: 0 3px 10px rgba(0,0,0,0.15);
}

.navbar-scrolled .nav-link {
  color: #353333;
}

/* Hover effect on links for larger screens */
@media (min-width: 1000px) {
  .nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #BB0404;
    visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  
  .nav-link:hover::before {
    width: 100%;
    visibility: visible;
  }
}

/* Responsive navbar adjustments for smaller screens */
@media (max-width: 767px) {
  .navbar {
    background-color: white;
    padding: 10px 15px;
  }

  .navbar-brand {
    max-width: 30%;
  }

  .navbar-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .nav-link {
    text-align: center;
    padding: 10px 0;
    font-size: 15px;
  }
}



/* Nav bar end*/

/* Hero*/

.custom-btn-hero {
  background-color: #BB0404;
  color: #fff;
  outline-color: #BB0404;
}

.custom-btn-hero:hover {
  color: white;
  background-color: #920909 ; /* Change the background color on hover */
}

.custom-btn-hero2 {
  color: #BB0404;
  border-color: red ;
}

.custom-btn-hero2:hover {
  color: white;
  background-color: #BB0404 ; /* Change the background color on hover */
}

.hero-p{
  font-size: 20px;
}

@media (max-width: 576px) {
  .img-mobile {
    margin-bottom: -30px;
      max-width: 285px; /* Adjust the max-width to your preference */
  }

  .hero-p {
    font-size: 20px;
  }
}
     
/* Hero Ends*/

/* Clients Starts*/

.logos::before, 
        .logos::after {
            content: '';
            width: 300px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 2;
        }

        .logos::before {
            left: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
        }

        .logos::after {
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
        }

        .logos-slide {
            animation: 25s slide infinite linear;
        }

        .logos:hover .logos-slide {
            animation-play-state: paused;
        }

        .logos-slide img {
            height: 150px;
            margin: 0 30px;
        }

        @keyframes slide {
            from{
                transform: translateX(0);
            }

            to {
                transform: translateX(-100%);
            }
        }

        @media (max-width: 767px) {
          .logos::before {
            left: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
        }
    
        .logos::after {
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
        }
        
        .logos-slide img {
          height: 160px;
          margin: 0 10px;
      }
      }

/* Clients Ends*/



/* Cards start*/

.cards-class{
  width: 100%;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .overlay-cards {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container:hover .overlay-cards {
  opacity: 1;
}

.btn-primary {
  display: none;
  background-color: #BB0404;
  border-color: #BB0404;
}

.btn-primary:hover{
  background-color: #920909;
  border-color: #920909;
}
.overlay-cards:hover .btn-primary {
  display: block;
}

  /* Cards end*/

.head-text {
    font-weight: 340;
    display: inline-block;
    padding-bottom: 10px;
    position: relative;
  }

.head-text:before{
    content: "";
    position: absolute;
    width: 25%;
    height: 1px;
    bottom: 0;
    left: 1%;
    border-bottom: 2px solid #BB0404;
}



/* Bussinuss Unit Text end*/

/* CEO Message Strat*/
.ceo-m {
  margin-top: 30px; /* Add some space above the container */
  padding: 80px; /* Add padding for better spacing */
  background-color: #ffffff33; /* Set a light background color */
  border-radius: 20px; /* Round the corners of the container */
  margin-bottom: 20px; /* Add some space above the content */
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05); /* Adjust the spread radius as needed */
}

.ceo-m img{
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1); /* Transparent border with 2px width */
  margin-top: 10px;
  margin-left: 40px;

}

/* Define font for quotes */
blockquote {
  font-family: 'Georgia', serif; /* Use Georgia or any other serif font */
  font-style: italic; /* Italicize the font for quotes */
  font-size: 1.2em; /* Adjust the font size as needed */
  color: #333; /* Set the color of the text */
  margin: 0; /* Reset margin to avoid browser default */
  padding: 10px 20px; /* Add padding for better readability */
  border-left: 5px solid #ccc; /* Add a left border for emphasis */
}

.ceo-m .home-about-text{
    font-size: 16px; /* Set a standard font size for the text */
    line-height: 1.6; /* Increase line height for better readability */
    color: #666; /* Set a slightly darker color for the text */
}

@media (max-width: 767px) {
  .ceo-m {
    padding: 30px; /* Adjust padding for smaller screens */
    border-radius: 10px; /* Reduce border radius for smaller screens */
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05); /* Adjust shadow for smaller screens */
  }

  .ceo-m img {
    border: none; /* Remove border for images on smaller screens */
    margin-left: 0px;
    margin-bottom: 20px;
  }

  blockquote {
    font-size: 1em; /* Adjust font size for quotes on smaller screens */
    padding: 10px; /* Adjust padding for quotes on smaller screens */
    border-left: 3px solid #ccc; /* Reduce border size for quotes on smaller screens */
  }

  .ceo-m .home-about-text {
    font-size: 14px; /* Reduce font size for text on smaller screens */
  }
}


/* CEO Message end*/

/* Key projects Filter start*/

.portfolio-filters{
  text-align: center;
}


.portfolio-filters .button {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  margin: 0;
  font-size: 16px;
  font-weight: 350;
  line-height: 1;
  margin-bottom: 40px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  font-family: var(--heading-font);
  border: none;
}

.portfolio-filters .button:hover,
.portfolio-filters .button.active {
  color: white;
  background-color: #BB0404;
}

.portfolio-filters .button:first-child {
  margin-left: 0;
}

.portfolio-filters .button:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio-filters .button {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}
/* Key projects Filter End*/


/* Key projects image grid start*/

.portfolio{
  width: 100%;
  padding: 30px 8%;
  background-color: var(--body-clr);
  margin-bottom: 200px; /* Adjust the value as needed */
}

.portfolio .section-head{
  max-width: 700px;
  margin: 0px auto 25px;
  text-align: center;
}
.section-head h1{
  position: relative;
  font-size: 32px;
  margin: 10px 0px 30px;
  color: var(--primary-clr);
}

div.gallery{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.gallery .item{
  position: relative;
  margin: 4px;
  width: calc(33.33% - 8px);
  overflow: hidden;
  cursor: pointer;
}
.item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: 0.3s;
}

div.gallery .item:hover img{
  transform: scale(1.15);
}



@media(max-width: 600px){
  .item .overlay{
    opacity: 1;
  }
}



/* Key projects image grid end*/

/* Bussinuss Unit Text start*/


.custom-sidebar {
  padding: 10px; /* Adjust as needed */
}

.custom-nav-link {
  color: #333; /* Text color of the links */
  padding: 8px 50px; /* Padding around the links */
  margin-bottom: 5px; /* Margin between each link */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.04); /* Adjust the spread radius as needed */
  margin-top: 10px;
  font-weight: 600;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  width: 100%; /* Ensure links fill the sidebar width */
  display: inline-block; /* Ensure links are displayed as blocks */
  margin-right: 30px;
}


.custom-nav-link:hover {
  background-color: #ffffff; /* Background color on hover */
  color: #BB0404;
}

.equal-height {
  height: 100%; /* Set the height to 100% */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.dividerClass {
  border: 2px solid #BB0404;
}

@media (max-width: 767px) {
  .custom-sidebar {
    margin-top: -60px; /* Adjust as needed */
  }
  
}



/* Bussinuss Unit Text end*/

div.home-gallery{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

div.home-gallery .item{
  position: relative;
  margin: 4px;
  width: calc(33.33% - 8px);
  overflow: hidden;
  cursor: pointer;
}



@media(max-width: 1024px){
  div.home-gallery  .item{
    width: calc(50% - 8px);
  }
}

@media(max-width: 600px){
  div.home-gallery  .item{
    width: 50%;
    margin: 4px 0px;
    border-right: 4px solid transparent
  }

}

.selection-section {
  background: #e0dfdf;
}

/* Stats Section - Home Page
------------------------------*/
.stats {
  --default-color: #ffffff;
  --default-color-rgb: 255, 255, 255;
  --background-color: #000000;
  --background-color-rgb: 0, 0, 0;
  position: relative;
  padding: 100px 0;
  transition: opacity 1s ease-in-out;
}

.stats img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

.stats:before {
  content: "";
  background: rgba(var(--background-color-rgb), 0.6);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.stats .container {
  position: relative;
  z-index: 3;
}

.stats .stats-item {
  padding: 30px;
  width: 100%;
}

.stats .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--default-color);
  font-weight: 700;
}

.stats .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 16px;
  font-weight: 700;
  color: rgba(var(--default-color-rgb), 0.6);
}


@media (max-width: 767px) {
  .stats {
    padding: 60px 0; /* Adjust the padding for smaller screens */
  }

  .stats .stats-item {
    padding: 20px; /* Adjust the padding for smaller screens */
  }

  .stats .stats-item span {
    font-size: 36px; /* Reduce the font size for smaller screens */
  }

  .stats .stats-item p {
    font-size: 14px; /* Reduce the font size for smaller screens */
  }
}


.show {
  opacity: 1;
}

.btn.btn-mdd {
  background-color: #BB0404;
  border-radius: 0;
  padding: 12px;
  width: 17%;
  transition: background-color 0.3s ease-in-out;
  color: white;
}

.btn.btn-mdd:hover {
  background-color: #920909;
  color: #fff;
}

@media (max-width: 767px) {

  .btn.btn-mdd {
    width: 50%; /* Set button width to 100% on smaller screens */
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  --background-color: #ffffff;
  color: var(--default-color);
  background-color: rgba(239, 237, 237, 0.742);
  font-size: 14px;
  padding-bottom: 50px;
}


.footer-img {
  max-height: 80px;
  margin-bottom: 10px;
  object-fit: contain; /* Fit the image within its container without stretching */
  width: 60%;
}

.footer-links {
  margin-bottom: 30px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li {
  padding: 2px 0;
  align-items: center;
}

.widget.footer-links ul li a {
  color: #6c757d; /* Default link color */
  transition: color 0.3s ease; /* Smooth transition */
  text-decoration: none;
}

.widget.footer-links ul a:hover {
  color: #BB0404; /* Red color on hover */
  text-decoration: none; /* Remove default underline */
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer-credits a{
  text-decoration: none;
  color: #BB0404;
}

.contact-number a{
  text-decoration: none !important;
  color: black;
}

.widget-logo {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content horizontally */
}

.widget-logo img {
  margin-bottom: 10px; /* Adjust margin to create space between image and text */
}

.widget-title{
  color: #BB0404;
}



@media (min-width: 992px) {
  .widget-contact, .widget-hours, .footer-links{
    margin-left: 40px;
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

.contact {
  margin-bottom: 270px; /* Adjust the value as needed */
}

.contact .info-item {
  margin-top: 80px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 20px 0 30px 0;
}
.contact .info-item a{
  text-decoration: none;
  color: black; /* Default link color */
  ;
}

.contact .info-item i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: var(--color-primary);
  border-radius: 50%;
  border: 2px dotted #BB0404;
}

.contact .info-item h3 {
  font-size: 20px;
  color: #6c757d;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}



/* Styles for screens smaller than 768px (mobile devices) */
@media (max-width: 767px) {
  .contact {
    margin-bottom: 50px; /* Adjust the margin for smaller screens */
    margin-right: 30px;
  }

  .contact .info-item {
    margin-top: 20px; /* Adjust margin for smaller screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .contact .info-item i {
    width: 30px; /* Reduce icon size for smaller screens */
    height: 30px;
    font-size: 16px; /* Reduce icon size for smaller screens */
  }

  .contact .info-item h3 {
    font-size: 14px; /* Reduce heading font size for smaller screens */
    margin: 8px 0; /* Adjust margin for smaller screens */
  }

  .contact .info-item p {
    font-size: 12px; /* Reduce paragraph font size for smaller screens */
  }

  .contact .php-email-form {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .contact .php-email-form input,
  .contact .php-email-form textarea {
    font-size: 12px; /* Reduce input and textarea font size for smaller screens */
  }

  .contact .php-email-form button[type=submit] {
    padding: 8px 20px; /* Adjust button padding for smaller screens */
    font-size: 12px; /* Reduce button font size for smaller screens */
  }
}

/* About Starts */

.about-header {
  margin-top: 150px;
}


.team3 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.team3 h1,
.team3 h2,
.team3 h3,
.team3 h4,
.team3 h5,
.team3 h6 {
  color: #3e4555;
}

.team3 .font-weight-medium {
  font-weight: 500;
}

.team3 .bg-light {
  background-color: #f4f8fa !important;
}

.team3 .subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 13px;
}

.team3 ul {
  margin-top: 30px;
}

.team3 h5 {
  line-height: 22px;
  font-size: 18px;
}


.team3 .title {
  margin: 30px 0 0 0;
}

.team3 .subtitle {
  margin: 0 0 20px 0;
  font-size: 13px;
}

